import React from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

function AboutPage({ location, data }) {
  const siteTitle = _get(data, 'site.siteMetadata.title')
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Thuan Nguyen" />
      <main>
        <h1>✋ About me</h1>
        <pre className="language-python">
          <code>Hello World</code>
        </pre>
      </main>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
